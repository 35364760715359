export const USER_STATUSES = {
  u: "unauthorized",
  t: "trial",
  p: "paid",
};
export const NUMBERS = {
  option1: 1,
  option2: 2,
  option3: 3,
  option4: 4,
};
export const PATH = {
  option2: {
    en: "https://learn.amzsc.amzgb.com/uninstall-survey-index?utm_source=uninstall&utm_medium=pro_uninstall&utm_campaign=survey",
    cn: "https://learn.amzsc.amzgb.com/uninstall-survey-cn-index?utm_source=uninstall&utm_medium=pro_uninstall&utm_campaign=survey",
  },
};